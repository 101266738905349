import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/navigation'

import isClient from '@equifax-ui/utils/browser/isClient'
import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { usePlans } from '@/hooks/usePlans'

const columnGap = 16

export const usePlansSection = () => {
  const [expandedPlans, setExpandedPlans] = useState({})
  const [visibleElementIndex, setVisibleElementIndex] = useState(2)
  const [isPaymentDetailsOpen, setIsPaymentDetailsOpen] = useState(false)

  const router = useRouter()

  const listRef = useRef()
  const itemsRef = useRef([])

  const { postpaidPlans } = usePlans()

  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  useEffect(() => {
    const firstVisibleCardIndex = isMobile ? 2 : 0
    const nextVisibleCard = itemsRef.current[firstVisibleCardIndex]

    scrollList(nextVisibleCard)
    setVisibleElementIndex(firstVisibleCardIndex)
  }, [isMobile])

  const scrollList = (nextVisibleCard, direction) => {
    const { top, width } = nextVisibleCard.getBoundingClientRect()

    const scrollAmount = width / 2 + columnGap * 3
    const left = direction === 'left' ? -scrollAmount : scrollAmount

    listRef.current.scrollBy({
      top,
      left,
      behavior: 'smooth'
    })
  }

  const isOnScreen = (element) => {
    const { x } = element.getBoundingClientRect()
    const { width } = listRef.current.getBoundingClientRect()

    const minScrollSize = isMobile ? 0 : 80
    const maxScrollSize = width / 3

    return x > minScrollSize && x < maxScrollSize
  }

  const onScroll = () => {
    const newVisibleCardIndex =
      itemsRef.current.findIndex((card) => isOnScreen(card)) ?? 0

    if (
      newVisibleCardIndex >= 0 &&
      newVisibleCardIndex !== visibleElementIndex
    ) {
      setVisibleElementIndex(newVisibleCardIndex)
    }
  }

  const onClickController = (direction) => {
    const nextVisibleCard = itemsRef.current[visibleElementIndex]

    scrollList(nextVisibleCard, direction)
  }

  const onSelectPlan = (planId) => {
    isClient() && sessionStorage.setItem('bvs-plan', planId)
    router.push('/formulario/dados-basicos')
  }

  return {
    listRef,
    itemsRef,
    postpaidPlans,
    expandedPlans,
    visibleElementIndex,
    isMobile,
    isPaymentDetailsOpen,
    setExpandedPlans,
    setIsPaymentDetailsOpen,
    onScroll,
    onClickController,
    onSelectPlan
  }
}
